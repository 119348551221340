import { ConnectWallet, ThirdwebSDK, Web3Button, useAddress } from "@thirdweb-dev/react";
import "./styles/Home.css";
import abi from './abi.json'
import abiToken from './abiToken.json'
import abiComprarQuemar from './abiComprarQuemar.json'
import { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
export default function Home() {
  const wallet = useAddress()
  const [balance, setBalance] = useState()
  const [currentNFT, setCurrentNFT] = useState(69);
  const inputNFTs = useRef(0)
  const inputNFTs2 = useRef(0)
  const inputNFTs3 = useRef(0)
  const [quemados, setQuemados] = useState(0)
  const [balanceToken, setBalanceToken] = useState(0)
  const [aprobado, setAprobado] = useState(false)
  const [aprobado2, setAprobado2] = useState(false)
  const [totalMinteado, settotalMinteado] = useState(0)
  const [coLibre, setCoLibre] = useState(0)
  const showBalance = async() =>{
    console.log(wallet)
    const sdk = new ThirdwebSDK("polygon");
    const contract = await sdk.getContract(
      "0x3D7593DAD82286c3e4CD56925f45F58278BB477c", 
      abi,
    );
    const contractComprar = await sdk.getContract(
      "0xDdF63539728e91ed3fB8E67ECEaDD19eE7D07D10", 
      abiToken,
    );
    if(wallet != undefined){
      console.log("tesat")
      console.log(wallet)
    const balance  = await contract.call(
      "balanceOf" ,
      [wallet]
    );
    console.log("tesat2")
    const minteado  = await contract.call(
      "totalSupply" ,
      []
    );
    const quemados  = await contractComprar.call(
      "burnedTokens" ,
      [wallet]
    );
    const balanceToken  = await contractComprar.call(
      "balanceOf" ,
      [wallet]
    );
    setBalanceToken(parseInt(balanceToken._hex,16))
    setQuemados(parseInt(quemados._hex,16))
    settotalMinteado(parseInt(minteado._hex, 16).toString())
    setBalance(parseInt(balance._hex, 16).toString())}

    const contractToken = await sdk.getContract(
      "0xDdF63539728e91ed3fB8E67ECEaDD19eE7D07D10", 
      abiToken,
    );

    const balanceOf  = await contractToken.call(
      "balanceOf" ,
      ["0x3D7593DAD82286c3e4CD56925f45F58278BB477c"]
    );
    console.log(balanceOf)
  const decimal =   parseInt(balanceOf._hex, 16)
  const decimal2 = decimal.toLocaleString()
  const weiStringWithoutCommas = decimal2.replace(/,/g, '');
  const weiAmount = ethers.BigNumber.from(weiStringWithoutCommas);
  const etherAmount = ethers.utils.formatEther(weiAmount);
  console.log(etherAmount)
  setCoLibre(etherAmount)
  }
  useEffect(() => {
    showBalance()


    const intervalId = setInterval(() => {
      setCurrentNFT((prevNFT) => (prevNFT < 80 ? prevNFT + 1 : 69));
    }, 1000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);

  }, [wallet])
  
  return (
    <main className="main">
      <div className="container">
        <div className="header">
          <h1 className="title">
            Ukumari
           
          </h1>
          <div className="connect">
          </div>
        </div>
        <center> <a
            className="card"
            target="_blank"
            rel="noopener noreferrer"
          >
          <center> <img
              style={{width:"50%", borderRadius:"250px"}}
              src={`/images/fotosRandom/NFT_${currentNFT}.png`}
              alt="Placeholder preview of starter"
            /></center> 
            <div className="card-text">
             <center><h2 className="gradient-text-2">Mintea tu NFT</h2>
             <br></br>
              <br></br>
              <p>
                Presione el boton mint de abjo
              </p>
              <br></br>
            
             {
              aprobado
                  ?
                  <>
                  <input ref={inputNFTs} placeholder="Cantidad de NFTs" style={{borderRadius:"15px",padding:"10px"}}></input>
                  <br></br>
                  <br></br>
                <Web3Button
                    contractAddress="0x3D7593DAD82286c3e4CD56925f45F58278BB477c" // Your smart contract address
                    contractAbi={abi}
                    action={async (contract) => {
                      console.log( inputNFTs.current.value)
                      await contract.call(
                        "mint",
                        [
                          inputNFTs.current.value
                        ]
                      )
                    }}
                    >
                  Mint
                </Web3Button>
            </>
                :
                <>
                <Web3Button
                    contractAddress="0xc2132d05d31c914a87c6611c10748aeb04b58e8f" // Your smart contract address
                    contractAbi={abiToken}
                    action={async (contract) => {
                      console.log( inputNFTs.current.value)
                      await contract.call(
                        "approve",
                        [
                          "0x3D7593DAD82286c3e4CD56925f45F58278BB477c",
                          10000000000000
                        ]
                      )
                    }}
                    
                    onSubmit={() => {
                      setTimeout(() => {
                        setAprobado(true)
                      }, 10000);
                      }}
                    >
                  Aprobar
                </Web3Button>
                </>
            }
            <br></br>
            <br></br>
                <p>
                  Cantidad de NFTs de tu propiedad: {balance}
                  
                </p>
                <br></br>
                <p>Cantidad de toneladas de  CO2 disponible: {coLibre}</p>
                <br></br>
                <p>
                 {totalMinteado} / 800
                </p>


<br></br>
<br></br>
<br></br>
<br></br>
<h2 className="gradient-text-2">Comprar o compensar tokens</h2>
{
              aprobado2
                  ?
                  <>
                  <input ref={inputNFTs2} placeholder="Cantidad de tokens a comprar" style={{borderRadius:"15px",padding:"10px"}}></input>
                  <br></br>
                  <br></br>
                <Web3Button
                    contractAddress="0x5a274E52f0F26C23Bf4F46562029f1998728a35f" // Your smart contract address
                    contractAbi={abiComprarQuemar}
                    action={async (contract) => {
                      console.log( inputNFTs2.current.value)
                      await contract.call(
                        "buyTokens",
                        [
                          inputNFTs2.current.value
                        ]
                      )
                    }}
                    >
                  Comprar tokens UKU
                </Web3Button>

<br></br>
<br></br>
                <input ref={inputNFTs3} placeholder="Cantidad de tokens a quemar" style={{borderRadius:"15px",padding:"10px"}}></input>
                  <br></br>
                  <br></br>
                <Web3Button
                    contractAddress="0xDdF63539728e91ed3fB8E67ECEaDD19eE7D07D10" // Your smart contract address
                    contractAbi={abi}
                    action={async (contract) => {
                      console.log( inputNFTs3.current.value)
                      await contract.call(
                        "burn",
                        [
                          inputNFTs3.current.value
                        ]
                      )
                    }}
                    >
                  Compensar tokens  UKU
                </Web3Button>

            </>
                :
                <>
                <Web3Button
                    contractAddress="0xc2132d05d31c914a87c6611c10748aeb04b58e8f" // Your smart contract address
                    contractAbi={abiToken}
                    action={async (contract) => {
                      console.log( inputNFTs.current.value)
                      await contract.call(
                        "approve",
                        [
                          "0x5a274E52f0F26C23Bf4F46562029f1998728a35f",
                          10000000000000
                        ]
                      )
                    }}
                    
                    onSubmit={() => {
                      setTimeout(() => {
                        setAprobado2(true)
                      }, 10000);
                      }}
                    >
                  Aprobar
                </Web3Button>
                </>
            }
            <p>Total tokens quemados: {quemados}</p>
            <p>Balance de tokens: {balanceToken / 1000000000000000000}</p>
            </center>
            </div>
          </a></center>
        <div className="grid">
        
        </div>
      </div>
    </main>
  );
}


